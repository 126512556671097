.react-cookie-banner {
  background-color: rgba(255, 255, 255, 0.8) !important;
  position: fixed !important;
  height: inherit !important;
  padding: 10px 100px 10px 10px;
}

.cookie-message {
  color: #696969 !important;
  font-weight: 400 !important;
  line-height: 1 !important;
}

.button-close {
  border: 1px solid #d3d3d3 !important;
  right: 10px !important;
}

.react-cookie-banner-override {
  z-index: 10 !important;
}
