#auth0-container{
  align-self: center;
  width: 439px;
  height: 382px;
}

.auth0-lock-opened-in-frame,
.auth0-lock-widget,
.auth0-lock-widget-container
.auth0-lock-center,
.auth0-lock-cred-pane-internal-wrapper {
  height: 382px !important;
  width: 100% !important;
  margin: 0 !important;
}

.auth0-lock.auth0-lock .auth0-lock-header-bg,
.auth0-lock-header-bg-blur {
  background: none !important;
}

.auth0-lock-header {
  height: 131px;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo {
  margin: 25px 0 11px !important;
}

.auth0-lock-cred-pane {
  background-color: #F3F5F8 !important;
}

.auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon,
.auth0-lock-form{
  padding: 0 !important;
}

.auth0-lock-content {
  width: 439px !important;
}

.auth0-lock-form {
  padding: 48px 66px !important;
}

.auth0-lock-content-wrapper,
.auth0-lock-content-wrapper:nth-child(2) {
  overflow: hidden !important;
}

.auth0-lock.auth0-lock .auth0-lock-input-wrap {
  border: 1px solid #B1B9C3 !important;
}

.auth0-lock.auth0-lock .auth0-lock-form p {
  width: 100%;
  text-align: center;
}

.auth0-lock-submit {
  position: absolute !important;
  width: 100px !important;
  height: 44px !important;
  right: 66px !important;
  bottom: 48px !important;
  background: #22354B !important;
  border-radius: 3px !important;
  letter-spacing: initial !important;
}

.auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit {
  line-height: normal !important;
}

.auth0-lock-alternative {
  position: relative;
  bottom: -5px;
  text-align: left !important;;
}

.auth0-lock-alternative-link{
  font-size: 14px !important;
}

.auth0-loading-container .auth0-loading {
  position: relative;
  top: 40px !important;
  left: 48% !important;
  border-color: rgba(255, 255, 255, 255.4)
    rgba(255, 255, 255, 255.4)
    rgba(255, 255, 255, 255.2)
    rgba(255, 255, 255, 255.2) !important;
}

.auth0-lock.auth0-lock .auth0-lock-submit .auth0-loading-container .auth0-loading{
  top: -8px !important;
  left: 22px !important;
}

.auth0-lock-last-login-pane .auth0-lock-alternative {
  left: 0px !important;
  text-align: center !important;
  width: 100% !important;
}

.auth0-lock-last-login-pane.auth0-lock-alternative {
  bottom: initial !important;
}

.auth0-lock-name,
.auth0-label-submit span {
  display: none !important;
}

.auth0-lock-form div p {
  width: 100%;
}

.divider {
  height: 2px;
  display: block;
  width: 100%;
  position: absolute;
  top: 121px;
}

.auth0-global-message {
  position: absolute !important;
  width: 100% !important;
  padding: 10px 0 !important;
}

@media (max-width: 481px) {
  .auth0-lock-center,
  .auth0-lock-widget,
  .auth0-lock-widget-container,
  .auth0-lock-cred-pane-internal-wrapper {
    max-height: 382px;
  }

  .auth0-lock.auth0-lock .auth0-lock-input-block {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 347px) {
  .auth0-lock.auth0-lock .auth0-lock-form p {
    line-height: 1.5;
  }
}
