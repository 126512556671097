.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  padding: 12px 90px 12px 0;
}

.footer-item {
  margin-left: 15px;
  padding: 0 0 0 20px;
  display: inline-block;
  color: #204586;
  font-size: 14px;
  text-decoration: none;
}

@media (max-width: 481px) {
  .footer-container {
    flex-direction: column-reverse;
    padding: 10px;
  }

  .dropup .dropdown-menu {
    z-index: 1000001;
  }

  .footer-item {
    margin-top: 3px;
    margin-left: 0;
    padding: 0;
  }
}
