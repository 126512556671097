/* bootstrap overrides */
.btn {
  padding: 0 !important;
  border: 0 !important;
}

.btn-link {
  text-decoration: none !important;
  color: #204586 !important;
}

.btn-link:hover .btn-link:focus {
  text-decoration: none !important;
}
