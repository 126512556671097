.authorization-error-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  text-align: center;
}

.authorization-error-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 507px;
  min-height: 488px;
  background: #ffffff;
}

.authorization-error-logo {
  margin: 32px 0;
}

.authorization-error-divider {
  display: flex;
  width: 100%;
  height: 2px;
}

.authorization-error-bird {
  margin: 32px 0 12px 0;
}

.authorization-error-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.authorization-error-subtitle {
  margin: 18px 0 0 0;
  max-width: 439px;
}

.authorization-error-description {
  font-size: 14px;
  line-height: 140%;
  max-width: 439px;
  margin-top: 12px;
}
