* {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: url(https://storage.googleapis.com/assets.ecitapps.com/images/portal_background-v2.jpg)
    no-repeat
    center
    center
    fixed;
  background-size: cover;
}

.navy,
.blue,
.green {
  display: inline-block;
  height: 2px;
}

.navy {
  width: 56%;
  background: #0D2D4E;
}

.blue {
  width: 22%;
  background: #3B77B5;
}

.green {
  width: 22%;
  background: #73B783;
}

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*
 * See the Getting Started docs for more information:
 * http://getbootstrap.com/getting-started/#support-ie10-width
 */
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
