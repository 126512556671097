/* ========================================================================== *
 * Copyright 2014 USRZ.com and Pier Paolo Fumagalli                           *
 * -------------------------------------------------------------------------- *
 * Licensed under the Apache License, Version 2.0 (the "License");            *
 * you may not use this file except in compliance with the License.           *
 * You may obtain a copy of the License at                                    *
 *                                                                            *
 *  http://www.apache.org/licenses/LICENSE-2.0                                *
 *                                                                            *
 * Unless required by applicable law or agreed to in writing, software        *
 * distributed under the License is distributed on an "AS IS" BASIS,          *
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.   *
 * See the License for the specific language governing permissions and        *
 * limitations under the License.                                             *
 * ========================================================================== */

/* Define properties for our icons (default to the unknown language flag) */
.lang-xs { background-position: 0px  -473px; min-width: 14px; height: 11px; min-height: 11px; max-height: 11px; background-repeat: no-repeat; display: inline-block; background-image: url('languages.png'); }
.lang-sm { background-position: 0px -1172px; min-width: 22px; height: 16px; min-height: 16px; max-height: 16px; background-repeat: no-repeat; display: inline-block; background-image: url('languages.png'); }
.lang-lg { background-position: 0px -2134px; min-width: 30px; height: 22px; min-height: 22px; max-height: 22px; background-repeat: no-repeat; display: inline-block; background-image: url('languages.png'); }

/* Positions in our sprite for xs-sized images */
.lang-xs[lang=ar] { background-position: 0px     0px; }
.lang-xs[lang=be] { background-position: 0px   -11px; }
.lang-xs[lang=bg] { background-position: 0px   -22px; }
.lang-xs[lang=cs] { background-position: 0px   -33px; }
.lang-xs[lang=da] { background-position: 0px   -44px; }
.lang-xs[lang=de] { background-position: 0px   -55px; }
.lang-xs[lang=el] { background-position: 0px   -66px; }
.lang-xs[lang=en] { background-position: 0px   -77px; }
.lang-xs[lang=es] { background-position: 0px   -88px; }
.lang-xs[lang=et] { background-position: 0px   -99px; }
.lang-xs[lang=fi] { background-position: 0px  -110px; }
.lang-xs[lang=fr] { background-position: 0px  -121px; }
.lang-xs[lang=ga] { background-position: 0px  -132px; }
.lang-xs[lang=hi] { background-position: 0px  -143px; }
.lang-xs[lang=hr] { background-position: 0px  -154px; }
.lang-xs[lang=hu] { background-position: 0px  -165px; }
.lang-xs[lang=in] { background-position: 0px  -176px; }
.lang-xs[lang=is] { background-position: 0px  -187px; }
.lang-xs[lang=it] { background-position: 0px  -198px; }
.lang-xs[lang=iw] { background-position: 0px  -209px; }
.lang-xs[lang=ja] { background-position: 0px  -220px; }
.lang-xs[lang=ko] { background-position: 0px  -231px; }
.lang-xs[lang=lt] { background-position: 0px  -242px; }
.lang-xs[lang=lv] { background-position: 0px  -253px; }
.lang-xs[lang=mk] { background-position: 0px  -264px; }
.lang-xs[lang=ms] { background-position: 0px  -275px; }
.lang-xs[lang=mt] { background-position: 0px  -286px; }
.lang-xs[lang=nl] { background-position: 0px  -297px; }
.lang-xs[lang=nb] { background-position: 0px  -308px; }
.lang-xs[lang=pl] { background-position: 0px  -319px; }
.lang-xs[lang=pt] { background-position: 0px  -330px; }
.lang-xs[lang=ro] { background-position: 0px  -341px; }
.lang-xs[lang=ru] { background-position: 0px  -352px; }
.lang-xs[lang=sk] { background-position: 0px  -363px; }
.lang-xs[lang=sl] { background-position: 0px  -374px; }
.lang-xs[lang=sq] { background-position: 0px  -385px; }
.lang-xs[lang=sr] { background-position: 0px  -396px; }
.lang-xs[lang=sv] { background-position: 0px  -407px; }
.lang-xs[lang=th] { background-position: 0px  -418px; }
.lang-xs[lang=tr] { background-position: 0px  -429px; }
.lang-xs[lang=uk] { background-position: 0px  -440px; }
.lang-xs[lang=vi] { background-position: 0px  -451px; }
.lang-xs[lang=zh] { background-position: 0px  -462px; }

/* Positions in our sprite for sm-sized images */
.lang-sm[lang=ar] { background-position: 0px  -484px; }
.lang-sm[lang=be] { background-position: 0px  -500px; }
.lang-sm[lang=bg] { background-position: 0px  -516px; }
.lang-sm[lang=cs] { background-position: 0px  -532px; }
.lang-sm[lang=da] { background-position: 0px  -548px; }
.lang-sm[lang=de] { background-position: 0px  -564px; }
.lang-sm[lang=el] { background-position: 0px  -580px; }
.lang-sm[lang=en] { background-position: 0px  -596px; }
.lang-sm[lang=es] { background-position: 0px  -612px; }
.lang-sm[lang=et] { background-position: 0px  -628px; }
.lang-sm[lang=fi] { background-position: 0px  -644px; }
.lang-sm[lang=fr] { background-position: 0px  -660px; }
.lang-sm[lang=ga] { background-position: 0px  -676px; }
.lang-sm[lang=hi] { background-position: 0px  -692px; }
.lang-sm[lang=hr] { background-position: 0px  -708px; }
.lang-sm[lang=hu] { background-position: 0px  -724px; }
.lang-sm[lang=in] { background-position: 0px  -740px; }
.lang-sm[lang=is] { background-position: 0px  -756px; }
.lang-sm[lang=it] { background-position: 0px  -772px; }
.lang-sm[lang=iw] { background-position: 0px  -788px; }
.lang-sm[lang=ja] { background-position: 0px  -804px; }
.lang-sm[lang=ko] { background-position: 0px  -820px; }
.lang-sm[lang=lt] { background-position: 0px  -836px; }
.lang-sm[lang=lv] { background-position: 0px  -852px; }
.lang-sm[lang=mk] { background-position: 0px  -868px; }
.lang-sm[lang=ms] { background-position: 0px  -884px; }
.lang-sm[lang=mt] { background-position: 0px  -900px; }
.lang-sm[lang=nl] { background-position: 0px  -916px; }
.lang-sm[lang=nb] { background-position: 0px  -932px; }
.lang-sm[lang=pl] { background-position: 0px  -948px; }
.lang-sm[lang=pt] { background-position: 0px  -964px; }
.lang-sm[lang=ro] { background-position: 0px  -980px; }
.lang-sm[lang=ru] { background-position: 0px  -996px; }
.lang-sm[lang=sk] { background-position: 0px -1012px; }
.lang-sm[lang=sl] { background-position: 0px -1028px; }
.lang-sm[lang=sq] { background-position: 0px -1044px; }
.lang-sm[lang=sr] { background-position: 0px -1060px; }
.lang-sm[lang=sv] { background-position: 0px -1076px; }
.lang-sm[lang=th] { background-position: 0px -1092px; }
.lang-sm[lang=tr] { background-position: 0px -1108px; }
.lang-sm[lang=uk] { background-position: 0px -1124px; }
.lang-sm[lang=vi] { background-position: 0px -1140px; }
.lang-sm[lang=zh] { background-position: 0px -1156px; }

/* Positions in our sprite for lg-sized images */
.lang-lg[lang=ar] { background-position: 0px -1188px; }
.lang-lg[lang=be] { background-position: 0px -1210px; }
.lang-lg[lang=bg] { background-position: 0px -1232px; }
.lang-lg[lang=cs] { background-position: 0px -1254px; }
.lang-lg[lang=da] { background-position: 0px -1276px; }
.lang-lg[lang=de] { background-position: 0px -1298px; }
.lang-lg[lang=el] { background-position: 0px -1320px; }
.lang-lg[lang=en] { background-position: 0px -1342px; }
.lang-lg[lang=es] { background-position: 0px -1364px; }
.lang-lg[lang=et] { background-position: 0px -1386px; }
.lang-lg[lang=fi] { background-position: 0px -1408px; }
.lang-lg[lang=fr] { background-position: 0px -1430px; }
.lang-lg[lang=ga] { background-position: 0px -1452px; }
.lang-lg[lang=hi] { background-position: 0px -1474px; }
.lang-lg[lang=hr] { background-position: 0px -1496px; }
.lang-lg[lang=hu] { background-position: 0px -1518px; }
.lang-lg[lang=in] { background-position: 0px -1540px; }
.lang-lg[lang=is] { background-position: 0px -1562px; }
.lang-lg[lang=it] { background-position: 0px -1584px; }
.lang-lg[lang=iw] { background-position: 0px -1606px; }
.lang-lg[lang=ja] { background-position: 0px -1628px; }
.lang-lg[lang=ko] { background-position: 0px -1650px; }
.lang-lg[lang=lt] { background-position: 0px -1672px; }
.lang-lg[lang=lv] { background-position: 0px -1694px; }
.lang-lg[lang=mk] { background-position: 0px -1716px; }
.lang-lg[lang=ms] { background-position: 0px -1738px; }
.lang-lg[lang=mt] { background-position: 0px -1760px; }
.lang-lg[lang=nl] { background-position: 0px -1782px; }
.lang-lg[lang=nb] { background-position: 0px -1804px; }
.lang-lg[lang=pl] { background-position: 0px -1826px; }
.lang-lg[lang=pt] { background-position: 0px -1848px; }
.lang-lg[lang=ro] { background-position: 0px -1870px; }
.lang-lg[lang=ru] { background-position: 0px -1892px; }
.lang-lg[lang=sk] { background-position: 0px -1914px; }
.lang-lg[lang=sl] { background-position: 0px -1936px; }
.lang-lg[lang=sq] { background-position: 0px -1958px; }
.lang-lg[lang=sr] { background-position: 0px -1980px; }
.lang-lg[lang=sv] { background-position: 0px -2002px; }
.lang-lg[lang=th] { background-position: 0px -2024px; }
.lang-lg[lang=tr] { background-position: 0px -2046px; }
.lang-lg[lang=uk] { background-position: 0px -2068px; }
.lang-lg[lang=vi] { background-position: 0px -2090px; }
.lang-lg[lang=zh] { background-position: 0px -2112px; }

/* Labels for the unknown language */
.lang-lbl:after      { content: "Unknown language"; }
.lang-lbl-en:after   { content: "Unknown language"; }
.lang-lbl-full:after { content: "Unknown language"; }

/* Labels in native (default) language */
.lang-lbl[lang=ar]:after { content: "\000627\000644\000639\000631\000628\00064A\000629"; }
.lang-lbl[lang=be]:after { content: "\000411\000435\00043B\000430\000440\000443\000441\00043A\000456"; }
.lang-lbl[lang=bg]:after { content: "\000411\00044A\00043B\000433\000430\000440\000441\00043A\000438"; }
.lang-lbl[lang=cs]:after { content: "\00010Ce\000161tina"; }
.lang-lbl[lang=da]:after { content: "Dansk"; }
.lang-lbl[lang=de]:after { content: "Deutsch"; }
.lang-lbl[lang=el]:after { content: "\000395\0003BB\0003BB\0003B7\0003BD\0003B9\0003BA\0003AC"; }
.lang-lbl[lang=en]:after { content: "English"; }
.lang-lbl[lang=es]:after { content: "Espa\0000F1ol"; }
.lang-lbl[lang=et]:after { content: "Eesti"; }
.lang-lbl[lang=fi]:after { content: "Suomi"; }
.lang-lbl[lang=fr]:after { content: "Fran\0000E7ais"; }
.lang-lbl[lang=ga]:after { content: "Gaeilge"; }
.lang-lbl[lang=hi]:after { content: "\000939\00093F\000902\000926\000940"; }
.lang-lbl[lang=hr]:after { content: "Hrvatski"; }
.lang-lbl[lang=hu]:after { content: "Magyar"; }
.lang-lbl[lang=in]:after { content: "Bahasa\000020indonesia"; }
.lang-lbl[lang=is]:after { content: "\0000CDslenska"; }
.lang-lbl[lang=it]:after { content: "Italiano"; }
.lang-lbl[lang=iw]:after { content: "\0005E2\0005D1\0005E8\0005D9\0005EA"; }
.lang-lbl[lang=ja]:after { content: "\0065E5\00672C\008A9E"; }
.lang-lbl[lang=ko]:after { content: "\00D55C\00AD6D\00C5B4"; }
.lang-lbl[lang=lt]:after { content: "Lietuvi\000173"; }
.lang-lbl[lang=lv]:after { content: "Latvie\000161u"; }
.lang-lbl[lang=mk]:after { content: "\00041C\000430\00043A\000435\000434\00043E\00043D\000441\00043A\000438"; }
.lang-lbl[lang=ms]:after { content: "Bahasa\000020melayu"; }
.lang-lbl[lang=mt]:after { content: "Malti"; }
.lang-lbl[lang=nl]:after { content: "Nederlands"; }
.lang-lbl[lang=nb]:after { content: "Norsk"; }
.lang-lbl[lang=pl]:after { content: "Polski"; }
.lang-lbl[lang=pt]:after { content: "Portugu\0000EAs"; }
.lang-lbl[lang=ro]:after { content: "Rom\0000E2n\000103"; }
.lang-lbl[lang=ru]:after { content: "\000420\000443\000441\000441\00043A\000438\000439"; }
.lang-lbl[lang=sk]:after { content: "Sloven\00010Dina"; }
.lang-lbl[lang=sl]:after { content: "Sloven\000161\00010Dina"; }
.lang-lbl[lang=sq]:after { content: "Shqipe"; }
.lang-lbl[lang=sr]:after { content: "\000421\000440\00043F\000441\00043A\000438"; }
.lang-lbl[lang=sv]:after { content: "Svenska"; }
.lang-lbl[lang=th]:after { content: "\000E44\000E17\000E22"; }
.lang-lbl[lang=tr]:after { content: "T\0000FCrk\0000E7e"; }
.lang-lbl[lang=uk]:after { content: "\000423\00043A\000440\000430\000457\00043D\000441\00044C\00043A\000430"; }
.lang-lbl[lang=vi]:after { content: "Ti\001EBFng\000020vi\001EC7t"; }
.lang-lbl[lang=zh]:after { content: "\004E2D\006587"; }

/* Labels in english language */
.lang-lbl-en[lang=ar]:after { content: "Arabic"; }
.lang-lbl-en[lang=be]:after { content: "Belarusian"; }
.lang-lbl-en[lang=bg]:after { content: "Bulgarian"; }
.lang-lbl-en[lang=cs]:after { content: "Czech"; }
.lang-lbl-en[lang=da]:after { content: "Danish"; }
.lang-lbl-en[lang=de]:after { content: "German"; }
.lang-lbl-en[lang=el]:after { content: "Greek"; }
.lang-lbl-en[lang=en]:after { content: "English"; }
.lang-lbl-en[lang=es]:after { content: "Spanish"; }
.lang-lbl-en[lang=et]:after { content: "Estonian"; }
.lang-lbl-en[lang=fi]:after { content: "Finnish"; }
.lang-lbl-en[lang=fr]:after { content: "French"; }
.lang-lbl-en[lang=ga]:after { content: "Irish"; }
.lang-lbl-en[lang=hi]:after { content: "Hindi"; }
.lang-lbl-en[lang=hr]:after { content: "Croatian"; }
.lang-lbl-en[lang=hu]:after { content: "Hungarian"; }
.lang-lbl-en[lang=in]:after { content: "Indonesian"; }
.lang-lbl-en[lang=is]:after { content: "Icelandic"; }
.lang-lbl-en[lang=it]:after { content: "Italian"; }
.lang-lbl-en[lang=iw]:after { content: "Hebrew"; }
.lang-lbl-en[lang=ja]:after { content: "Japanese"; }
.lang-lbl-en[lang=ko]:after { content: "Korean"; }
.lang-lbl-en[lang=lt]:after { content: "Lithuanian"; }
.lang-lbl-en[lang=lv]:after { content: "Latvian"; }
.lang-lbl-en[lang=mk]:after { content: "Macedonian"; }
.lang-lbl-en[lang=ms]:after { content: "Malay"; }
.lang-lbl-en[lang=mt]:after { content: "Maltese"; }
.lang-lbl-en[lang=nl]:after { content: "Dutch"; }
.lang-lbl-en[lang=nb]:after { content: "Norwegian"; }
.lang-lbl-en[lang=pl]:after { content: "Polish"; }
.lang-lbl-en[lang=pt]:after { content: "Portuguese"; }
.lang-lbl-en[lang=ro]:after { content: "Romanian"; }
.lang-lbl-en[lang=ru]:after { content: "Russian"; }
.lang-lbl-en[lang=sk]:after { content: "Slovak"; }
.lang-lbl-en[lang=sl]:after { content: "Slovenian"; }
.lang-lbl-en[lang=sq]:after { content: "Albanian"; }
.lang-lbl-en[lang=sr]:after { content: "Serbian"; }
.lang-lbl-en[lang=sv]:after { content: "Swedish"; }
.lang-lbl-en[lang=th]:after { content: "Thai"; }
.lang-lbl-en[lang=tr]:after { content: "Turkish"; }
.lang-lbl-en[lang=uk]:after { content: "Ukrainian"; }
.lang-lbl-en[lang=vi]:after { content: "Vietnamese"; }
.lang-lbl-en[lang=zh]:after { content: "Chinese"; }

/* Combined native/english labels */
.lang-lbl-full[lang=ar]:after { content: "\000627\000644\000639\000631\000628\00064A\000629\0000A0/\0000A0Arabic"; }
.lang-lbl-full[lang=be]:after { content: "\000411\000435\00043B\000430\000440\000443\000441\00043A\000456\0000A0/\0000A0Belarusian"; }
.lang-lbl-full[lang=bg]:after { content: "\000411\00044A\00043B\000433\000430\000440\000441\00043A\000438\0000A0/\0000A0Bulgarian"; }
.lang-lbl-full[lang=cs]:after { content: "\00010Ce\000161tina\0000A0/\0000A0Czech"; }
.lang-lbl-full[lang=da]:after { content: "Dansk\0000A0/\0000A0Danish"; }
.lang-lbl-full[lang=de]:after { content: "Deutsch\0000A0/\0000A0German"; }
.lang-lbl-full[lang=el]:after { content: "\000395\0003BB\0003BB\0003B7\0003BD\0003B9\0003BA\0003AC\0000A0/\0000A0Greek"; }
.lang-lbl-full[lang=en]:after { content: "English\0000A0/\0000A0English"; }
.lang-lbl-full[lang=es]:after { content: "Espa\0000F1ol\0000A0/\0000A0Spanish"; }
.lang-lbl-full[lang=et]:after { content: "Eesti\0000A0/\0000A0Estonian"; }
.lang-lbl-full[lang=fi]:after { content: "Suomi\0000A0/\0000A0Finnish"; }
.lang-lbl-full[lang=fr]:after { content: "Fran\0000E7ais\0000A0/\0000A0French"; }
.lang-lbl-full[lang=ga]:after { content: "Gaeilge\0000A0/\0000A0Irish"; }
.lang-lbl-full[lang=hi]:after { content: "\000939\00093F\000902\000926\000940\0000A0/\0000A0Hindi"; }
.lang-lbl-full[lang=hr]:after { content: "Hrvatski\0000A0/\0000A0Croatian"; }
.lang-lbl-full[lang=hu]:after { content: "Magyar\0000A0/\0000A0Hungarian"; }
.lang-lbl-full[lang=in]:after { content: "Bahasa\000020indonesia\0000A0/\0000A0Indonesian"; }
.lang-lbl-full[lang=is]:after { content: "\0000CDslenska\0000A0/\0000A0Icelandic"; }
.lang-lbl-full[lang=it]:after { content: "Italiano\0000A0/\0000A0Italian"; }
.lang-lbl-full[lang=iw]:after { content: "\0005E2\0005D1\0005E8\0005D9\0005EA\0000A0/\0000A0Hebrew"; }
.lang-lbl-full[lang=ja]:after { content: "\0065E5\00672C\008A9E\0000A0/\0000A0Japanese"; }
.lang-lbl-full[lang=ko]:after { content: "\00D55C\00AD6D\00C5B4\0000A0/\0000A0Korean"; }
.lang-lbl-full[lang=lt]:after { content: "Lietuvi\000173\0000A0/\0000A0Lithuanian"; }
.lang-lbl-full[lang=lv]:after { content: "Latvie\000161u\0000A0/\0000A0Latvian"; }
.lang-lbl-full[lang=mk]:after { content: "\00041C\000430\00043A\000435\000434\00043E\00043D\000441\00043A\000438\0000A0/\0000A0Macedonian"; }
.lang-lbl-full[lang=ms]:after { content: "Bahasa\000020melayu\0000A0/\0000A0Malay"; }
.lang-lbl-full[lang=mt]:after { content: "Malti\0000A0/\0000A0Maltese"; }
.lang-lbl-full[lang=nl]:after { content: "Nederlands\0000A0/\0000A0Dutch"; }
.lang-lbl-full[lang=nb]:after { content: "Norsk\0000A0/\0000A0Norwegian"; }
.lang-lbl-full[lang=pl]:after { content: "Polski\0000A0/\0000A0Polish"; }
.lang-lbl-full[lang=pt]:after { content: "Portugu\0000EAs\0000A0/\0000A0Portuguese"; }
.lang-lbl-full[lang=ro]:after { content: "Rom\0000E2n\000103\0000A0/\0000A0Romanian"; }
.lang-lbl-full[lang=ru]:after { content: "\000420\000443\000441\000441\00043A\000438\000439\0000A0/\0000A0Russian"; }
.lang-lbl-full[lang=sk]:after { content: "Sloven\00010Dina\0000A0/\0000A0Slovak"; }
.lang-lbl-full[lang=sl]:after { content: "Sloven\000161\00010Dina\0000A0/\0000A0Slovenian"; }
.lang-lbl-full[lang=sq]:after { content: "Shqipe\0000A0/\0000A0Albanian"; }
.lang-lbl-full[lang=sr]:after { content: "\000421\000440\00043F\000441\00043A\000438\0000A0/\0000A0Serbian"; }
.lang-lbl-full[lang=sv]:after { content: "Svenska\0000A0/\0000A0Swedish"; }
.lang-lbl-full[lang=th]:after { content: "\000E44\000E17\000E22\0000A0/\0000A0Thai"; }
.lang-lbl-full[lang=tr]:after { content: "T\0000FCrk\0000E7e\0000A0/\0000A0Turkish"; }
.lang-lbl-full[lang=uk]:after { content: "\000423\00043A\000440\000430\000457\00043D\000441\00044C\00043A\000430\0000A0/\0000A0Ukrainian"; }
.lang-lbl-full[lang=vi]:after { content: "Ti\001EBFng\000020vi\001EC7t\0000A0/\0000A0Vietnamese"; }
.lang-lbl-full[lang=zh]:after { content: "\004E2D\006587\0000A0/\0000A0Chinese"; }

/* One breaking space if we have an image */
.lang-xs:before,
.lang-sm:before,
.lang-lg:before  { content: '\0000A0'; }

/* Pad the image and remove the non-breaking space when we have both an icon and a label */
.lang-xs.lang-lbl,
.lang-xs.lang-lbl-en,
.lang-xs.lang-lbl-full  { padding-left: 16px; }
.lang-sm.lang-lbl,
.lang-sm.lang-lbl-en,
.lang-sm.lang-lbl-full  { padding-left: 24px; }
.lang-lg.lang-lbl,
.lang-lg.lang-lbl-en,
.lang-lg.lang-lbl-full  { padding-left: 32px; }

.lang-xs.lang-lbl:before,
.lang-sm.lang-lbl:before,
.lang-lg.lang-lbl:before,
.lang-xs.lang-lbl-en:before,
.lang-sm.lang-lbl-en:before,
.lang-lg.lang-lbl-en:before,
.lang-xs.lang-lbl-full:before,
.lang-sm.lang-lbl-full:before,
.lang-lg.lang-lbl-full:before  { content: ''; }

/* Adjustments for paragraphs */
.lang-lg,
.lang-lg:after       { top:   0px; position: relative; }
.lang-sm             { top:   1px; position: relative; }
.lang-sm:after       { top:  -1px; position: relative; }
.lang-xs             { top:   4px; position: relative; }
.lang-xs:after       { top:  -4px; position: relative; }

.lead>.lang-lg       { top:   2px; }
.lead>.lang-lg:after { top:  -2px; }
.lead>.lang-sm       { top:   6px; }
.lead>.lang-sm:after { top:  -6px; }
.lead>.lang-xs       { top:   8px; }
.lead>.lang-xs:after { top:  -8px; }

small>.lang-sm       { top:  -1px; }
small>.lang-sm:after { top:   1px; }
small>.lang-xs       { top:   2px; }
small>.lang-xs:after { top:  -2px; }

/* Adjustments for headings */
h1>.lang-lg       { top:   9px; }
h1>.lang-lg:after { top:  -9px; }
h1>.lang-sm       { top:  12px; }
h1>.lang-sm:after { top: -12px; }
h1>.lang-xs       { top:  14px; }
h1>.lang-xs:after { top: -14px; }

h2>.lang-lg       { top:   5px; }
h2>.lang-lg:after { top:  -5px; }
h2>.lang-sm       { top:   8px; }
h2>.lang-sm:after { top:  -8px; }
h2>.lang-xs       { top:  10px; }
h2>.lang-xs:after { top: -10px; }

h3>.lang-lg       { top:   1px; }
h3>.lang-lg:after { top:  -1px; }
h3>.lang-sm       { top:   5px; }
h3>.lang-sm:after { top:  -5px; }
h3>.lang-xs       { top:   8px; }
h3>.lang-xs:after { top:  -8px; }

h4>.lang-lg       { top:  -1px; }
h4>.lang-lg:after { top:   1px; }
h4>.lang-sm       { top:   1px; }
h4>.lang-sm:after { top:  -1px; }
h4>.lang-xs       { top:   4px; }
h4>.lang-xs:after { top:  -4px; }

h5>.lang-sm,
h5>.lang-sm:after { top:   0px; }
h5>.lang-xs       { top:   2px; }
h5>.lang-xs:after { top:  -2px; }

h6>.lang-sm,
h6>.lang-sm:after { top:   0px; }
h6>.lang-xs       { top:   1px; }
h6>.lang-xs:after { top:  -1px; }

/* Adjustments for buttons */
.btn>.lang-sm              { top:   2px; }
.btn>.lang-sm:after        { top:  -2px; }
.btn>.lang-xs              { top:   4px; }
.btn>.lang-xs:after        { top:  -4px; }

.btn.btn-xs>.lang-sm,
.btn.btn-xs>.lang-sm:after { top:   0px; }
.btn.btn-xs>.lang-xs       { top:   3px; }
.btn.btn-xs>.lang-xs:after { top:  -3px; }

.btn.btn-sm>.lang-sm,
.btn.btn-sm>.lang-sm:after { top:   0px; }
.btn.btn-sm>.lang-xs       { top:   3px; }
.btn.btn-sm>.lang-xs:after { top:  -3px; }

.btn.btn-lg>.lang-lg       { top:   1px; }
.btn.btn-lg>.lang-lg:after { top:  -1px; }
.btn.btn-lg>.lang-sm       { top:   3px; }
.btn.btn-lg>.lang-sm:after { top:  -3px; }
.btn.btn-lg>.lang-xs       { top:   6px; }
.btn.btn-lg>.lang-xs:after { top:  -6px; }
